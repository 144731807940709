import { Html } from "@react-three/drei";
import React from "react";
import { useSelector } from "react-redux";
import { LAYERS } from "../../constants/options";
import { useDispatch } from 'react-redux';
import { reqSetExploreModal, reqSetIsShowExploreModal } from '../../reduxs/explore-modal/action';
import { reqSetActiveTransportOption, reqSetIsShowGalleryModal, reqSetIsShowHotspotModal, reqSetSelectedHotspot } from '../../reduxs/home/action';
import { reqSetActivePrecinctID } from '../../reduxs/transport-options/action';

const Annotations = (props) => {

    const {
        controls,
        hotspot_groups
      } = props;

  const dispatch = useDispatch();
  const isShowFuture = useSelector((state) => state.home.isShowFuture);
  const filterCategory = useSelector((state) => state.home.filterCategory);
  const activeCategory = useSelector((state) => state.home.activeCategory);
  const subCategories = useSelector((state) => state.category.subCategories);
  const categories = useSelector((state) => state.category.categories);

  if (isShowFuture) {
    return <group />
  }

  const icons = [
    "fashion.svg",
    "restaurantsbars.svg",
    "arts.svg",
    "conveniences.svg",
    "services.svg",
    "consultancies.svg",
    "marina.svg"
  ];
  const category = categories.find(c => c.id === activeCategory);

  let filterGroups = [];

  if (filterCategory == undefined || filterCategory.length == 0) {
    if (activeCategory != undefined && subCategories != undefined && subCategories.length > 0) {
        let subIds = subCategories.map((item) => item.id);
        hotspot_groups.forEach(group => {
        group.picked = false;
        group.filterChildren = [];
        group.children.forEach( child => {
          child.sub_category_ids.forEach( id => {
            if (subIds.includes(id)) {
              if (group.picked == false) {
                filterGroups.push(group);
                group.picked = true;
              }
              if (!group.filterChildren.includes(child)) {
                group.filterChildren.push(child);
              }  
            }
          })
        })
      });
    } else {
        hotspot_groups.forEach(group => {
        group.picked = false;
        group.filterChildren = [];
        group.children.forEach( child => {
            if (child.sub_category_ids.length == 0) {
            if (group.picked == false) {
                filterGroups.push(group);
                group.picked = true;
            }
            group.filterChildren.push(child);
            }
        })
        });
    }
  } else {
    hotspot_groups.forEach(group => {
        group.picked = false;
        group.filterChildren = [];
        group.children.forEach( child => {
        child.sub_category_ids.forEach( id => {
            if (filterCategory.includes(id)) {
            if (group.picked == false) {
                filterGroups.push(group);
                group.picked = true;
            }
            if (!group.filterChildren.includes(child)) {
                group.filterChildren.push(child);
            }  
            }
        })
        })
    });
    }
      
      return (
        <group>
           {filterGroups.map((group) => {
            group.filterChildren.sort((a,b) => a.name - b.name);
            console.log('Luan group: ', group.filterChildren);
            const names = group.filterChildren.map((hotspot, key) => { return hotspot.name; });
            return (
              <Html className='hotspot-parent' key={group.id} zIndexRange={[10, 0]} sprite={true} distanceFactor={50} position={[group.position.x, group.position.z, group.position.y]}>
                <div className="hotspot-content">
                  <div className='hotspot-grid-container'>
                    {names.map((item, index) => (
                      <button key={index} className="hotspot-grid-item" onClick={ () => {
                        let hotspot = group.filterChildren[index];
  
                        dispatch(reqSetIsShowGalleryModal(true));
                        dispatch(reqSetExploreModal(hotspot.link));
                      }}>
                        {category !== null ?
                        <img className='hotspot-item-img' src={`./icons/${icons[category.order - 1]}`} alt="" />
                        :
                        <>
                        <img className='hotspot-item-img' src='./icons/hotspotitem.svg' alt="" />
                        <div className='hotspot-title'>
                          <span>{item}</span>
                        </div>
                        </>
                        }
                      </button>
                  ))}
                  </div>
                  <div className='hotspot-img-container'>
                    <img className="hotspot-img-fluid" src="./icons/line.svg" alt="" />
                  </div>
                </div>
              </Html>
            );
        })}
        </group>
      );
}
  
export default Annotations;
