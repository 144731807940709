import * as type from './type';

const initialState = {
  data: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.GET_SUB_CATEGORY_LIST:
      return {
        ...state,
        data: action.data.data,
      };
    default:
      return state;
  }
};
