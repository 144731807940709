import * as Types from './type';

import currentHotspotApi from '../../apis/api/current-hotspot';
import hotspotApi from '../../apis/api/future-hotspot';
import _3dSettings from '../../apis/api/_3dSettings';
import assetApi from '../../apis/api/asset';
import getPageSettings from '../../apis/api/pageSettings';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import * as THREE from 'three';
import config from '../../config/index';
import { reqSetPage } from '../home/action';
import { PAGES } from '../../constants/options';

export const actIntroduction = (data) => ({
  type: Types.IS_INTRODUCTION,
  data,
});

export const setIsLoading = ({ isLoading }) => ({
  type: Types.SET_IS_LOADING,
  payload: isLoading,
});

// Receive Assets List
const receiveAssetsList = ({ data }) => ({
  type: Types.RECEIVE_ASSETS_LIST,
  payload: data,
});

export const fetchAssetsList = () => async (dispatch, getState) => {
  const assets = await assetApi.getAssetsList();
  dispatch(receiveAssetsList(assets));
  dispatch(fetchFbxFiles(assets.data));
};

// Receive Current Hotspots List
const receiveHotspotGroups = (data) => ({
  type: Types.RECEIVE_HOTSPOT_GROUPS,
  payload: data,
});

export const fetchCurrentHotspotsList = () => async (dispatch, getState) => {
  const data = await currentHotspotApi.getCurrentHotspotsList();
  const currentHotspots = data.data;
  let hotspotGroup = {};

  currentHotspots.forEach( hotspot => {
    const key = hotspot.hotspot_group_id.id;
    if (hotspotGroup[key] == undefined) {
      hotspotGroup[key] = {
        id: key,
        children: [hotspot],
        position: hotspot.hotspot_group_id.position,
        scale: hotspot.hotspot_group_id.scale
      }
    } else {
      hotspotGroup[key].children.push(hotspot);
    }
  });
  const result = Object.entries(hotspotGroup).map(([role, values]) => ({ ...values }));
  dispatch(receiveHotspotGroups(result));
};

// Receive Hotspots List
const receiveHotspotsList = ({ data }) => ({
  type: Types.RECEIVE_HOTSPOTS_LIST,
  payload: data,
});

export const fetchHotspotsList = () => async (dispatch, getState) => {
  const hotspots = await hotspotApi.getHotspotsList();
  dispatch(receiveHotspotsList(hotspots));
  //dispatch(fetchHsTexts(hotspots.data));
};

// Pages Settings
const receivePageSettings = ({ data }) => ({
  type: Types.RECEIVE_PAGES_SETTINGS,
  payload: data
});

export const fetchPagesSettings = () => async (dispatch, getState) => {
  const settings = await getPageSettings.getPageSettings();
  dispatch(receivePageSettings(settings));
};

// Receive 3D Settings
const receive3DSettings = ({ data }) => ({
  type: Types.RECEIVE_3D_SETTINGS,
  payload: data[0],
});

export const fetch3DSettings = () => async (dispatch, getState) => {
  const settings = await _3dSettings.get3DSettings();
  dispatch(receive3DSettings(settings));
};

// Receive FBX Files
const receiveFbxFiles = (files) => ({
  type: Types.RECEIVE_FBX_FILES,
  payload: files,
});

const receiveHsTexts = (textures) => ({
  type: Types.RECEIVE_HOTSPOT_TEXTURES,
  payload: textures,
});

export const fetchHsTexts = (hotspots) => async (dispatch, getState) => {
  let textures = [];
  var manager = new THREE.LoadingManager(function () {
    dispatch(receiveHsTexts(textures));
    // dispatch(setIsLoading(false));
  });

  const loader = new THREE.TextureLoader(manager);
  let requestUrls = new Set();
  hotspots.map((hotspot) => {
    requestUrls.add(`${config.SITE_URL}${hotspot.image_path}`);
  });

  requestUrls.forEach((url) => {
    loader.load(url, (res) => {
      res.url = url.split('/').pop();
      textures.push(res);
    });
  });
};

export const fetchFbxFiles = (objects) => async (dispatch, getState) => {
  const fbxLoader = new FBXLoader();
  let requestUrls = new Set();
  objects.map((item) => {
    requestUrls.add(`${config.SITE_URL}/uploads/${item['3d_filename']}`);
  });
  let fbxs = [];
  await (async (urls, maxConnections) => {
    let requestQueue = [];
    for (let i = 0; i < urls.length; i++) {
      const names = urls[i].split("/");
      const name = names[names.length - 1];
      if (requestQueue.length >= maxConnections) {
        let nextRequest = requestQueue.shift();
        await nextRequest.loader.then((response) => {
          response.name = name;
          fbxs.push(response);
        });
      }
      requestQueue.push({loader: fbxLoader.loadAsync(urls[i]), name: name});
    }

    let nextRequest;

    while ((nextRequest = requestQueue.shift())) {
      const name = nextRequest.name;
      await nextRequest.loader.then((response) => {
        response.name = name;
        fbxs.push(response);
      });
    }
  })(Array.from(requestUrls), 100).then(() => {
    dispatch(receiveFbxFiles(fbxs));
    dispatch(setIsLoading(false));
    setTimeout(function() {
      dispatch(reqSetPage(PAGES.LANDING_PAGE));
    }, 1000);
  });
};
