import categoryApi from '../../apis/api/category';
import subCategoryApi from '../../apis/api/sub-category';
import * as Types from './type';

const actGetCategoryList = (data) => ({
  type: Types.GET_CATEGORY_LIST,
  data,
});

const actGetSubCategoryList = (data) => ({
  type: Types.GET_SUB_CATEGORY_LIST,
  data,
})

export const reqGetCategoryList = () => (dispatch) => {
  return categoryApi
    .getCategoryList()
    .then((data) => {
      dispatch(actGetCategoryList(data));
    });
};

export const reqGetSubCategoryList = (query) => (dispatch) => {
  return subCategoryApi
    .getSubCategoryList(query)
    .then((data) => {
      dispatch(actGetSubCategoryList(data));
    });
};
