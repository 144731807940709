import React, { useEffect, useRef } from 'react';
import './style.scss';
import { useState } from 'react';
import LoadingIcon from '../loading-icon';
import { useDispatch } from 'react-redux';
import { reqGetUserProfile } from '../../reduxs/user/action';

const Loading = (props) => {
  const { isLoading, setIsIntroduction } = props;
  const [isShowVideo, setShowVideo] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reqGetUserProfile());
  }, []);

  return (
    <div id="loading-screen">
      <div className={`wrap-intro ${isShowVideo ? 'hide' : 'show'}`}>
        <div className="intro-content container">
          {isLoading && (
            <LoadingIcon />
          )}
        </div>
      </div>
    </div>
  );
};

export default Loading;
