import React from "react";
import { useSelector } from "react-redux";
import './index.scss';

const BottomNav = (props) => {
  const isShowFuture = useSelector((state) => state.home.isShowFuture);
  const { handleClickFuture, handleClickCurrent } = props;

  return (
    <div className="wrap-bottom-nav">
      <div onClick={handleClickCurrent} className={`item justify-content-end ${isShowFuture ? '' : 'active'}`}>
        <div className="current">
          <label htmlFor="">Current</label>
          <svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.0703287 0.0706063C0.0252416 0.116766 0 0.178732 0 0.243258C0 0.307784 0.0252416 0.36975 0.0703287 0.41591L2.58929 3L0.0703287 5.58357C0.0252416 5.62973 0 5.6917 0 5.75623C0 5.82075 0.0252416 5.88272 0.0703287 5.92888C0.0922442 5.95139 0.118447 5.96928 0.14739 5.98149C0.176333 5.99371 0.20743 6 0.238845 6C0.27026 6 0.301357 5.99371 0.3303 5.98149C0.359243 5.96928 0.385446 5.95139 0.407361 5.92888L3.08605 3.18041C3.1331 3.13214 3.15943 3.0674 3.15943 3C3.15943 2.9326 3.1331 2.86786 3.08605 2.81959L0.407361 0.0711233C0.385446 0.0486148 0.359243 0.0307247 0.3303 0.0185091C0.301357 0.00629352 0.27026 0 0.238845 0C0.20743 0 0.176333 0.00629352 0.14739 0.0185091C0.118447 0.0307247 0.0922442 0.0486148 0.0703287 0.0711233V0.0706063Z" fill="#9BADB7" />
          </svg>
        </div>
      </div>
      <div onClick={handleClickFuture} className={`item ${isShowFuture ? 'active' : ''}`}>
        <div className="future">
          <label htmlFor="">Future</label>
          <svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.0703287 0.0706063C0.0252416 0.116766 0 0.178732 0 0.243258C0 0.307784 0.0252416 0.36975 0.0703287 0.41591L2.58929 3L0.0703287 5.58357C0.0252416 5.62973 0 5.6917 0 5.75623C0 5.82075 0.0252416 5.88272 0.0703287 5.92888C0.0922442 5.95139 0.118447 5.96928 0.14739 5.98149C0.176333 5.99371 0.20743 6 0.238845 6C0.27026 6 0.301357 5.99371 0.3303 5.98149C0.359243 5.96928 0.385446 5.95139 0.407361 5.92888L3.08605 3.18041C3.1331 3.13214 3.15943 3.0674 3.15943 3C3.15943 2.9326 3.1331 2.86786 3.08605 2.81959L0.407361 0.0711233C0.385446 0.0486148 0.359243 0.0307247 0.3303 0.0185091C0.301357 0.00629352 0.27026 0 0.238845 0C0.20743 0 0.176333 0.00629352 0.14739 0.0185091C0.118447 0.0307247 0.0922442 0.0486148 0.0703287 0.0711233V0.0706063Z" fill="#9BADB7" />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default BottomNav;
