import { Html } from "@react-three/drei";
import React from "react";
import { useSelector } from "react-redux";
import { LAYERS } from "../../constants/options";
import { useDispatch } from 'react-redux';
import { reqSetExploreModal, reqSetIsShowExploreModal } from '../../reduxs/explore-modal/action';
import { reqSetActiveTransportOption, reqSetIsShowGalleryModal, reqSetIsShowHotspotModal, reqSetSelectedHotspot } from '../../reduxs/home/action';
import { reqSetActivePrecinctID } from '../../reduxs/transport-options/action';
import * as THREE from 'three';

const FutureAnnotations = (props) => {

    const {
        hotspots
      } = props;

  const isShowFuture = useSelector((state) => state.home.isShowFuture);

  if (!isShowFuture) {
    return <group />
  }

  return (
    <group>
      {hotspots.map((hotspot, key) => {
        return (
          <Html className='hotspot-parent' key={key} zIndexRange={[10, 0]} sprite={true} distanceFactor={50} position={[hotspot.position.x, hotspot.position.z, hotspot.position.y]} scale={[100,100,100]}>
            <div className="hotspot-content">
              <div className='hotspot-text'>
                <h1>
                  {hotspot.name}
                </h1>
              </div>
              <div className='hotspot-img-container'>
                <img className="hotspot-img-fluid" src="./icons/line.svg" alt="" />
              </div>
            </div>
          </Html>
        );})
      }
    </group>
  )
}
  
export default FutureAnnotations;
