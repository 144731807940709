import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/loading';
import _3dSettings from '../../apis/api/_3dSettings';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useHistory } from 'react-router-dom';

import './index.scss';

import { reqSetSelectedUnit, reqIsShowGallery, reqIsShowFloorplan, reqSetIsShowPrecinctDetail } from '../../reduxs/unit-explore/action';
import { reqSetIsShowExploreModal } from '../../reduxs/explore-modal/action';
import {
  reqSetPage,
  reqSetActiveTransportOption,
  reqSetActiveTransportOptionDistricts,
  reqSetIsShowEndGuideTenantSession,
  reqSetIsShowFilterPanel,
  reqSetIsShowFuture,
  reqSetActiveCategory,
  reqFilterCategory,
  reqSetIsShowGalleryModal
} from '../../reduxs/home/action';
import { PAGES, LAYERS, CATEGORIES } from '../../constants/options';
import { threePosition } from '../../helper/threeHeper';
import { reqSetIsShowPrecinctExploreDetail, reqSetIsShowReplayVideo } from '../../reduxs/precinct-explore/action';
import { reqSetActivePrecinctID } from '../../reduxs/transport-options/action';
import { setColor2 } from '../../helper/threeHeper';
import HeaderNav from '../../components/header-nav';
import FilterPanel from '../../components/filter-panel';
import BottomNav from '../../components/bottom-nav';
import FilterPanelCollapse from '../../components/filter-panel/filter-panel-collapse';
import HotspotPopup from '../../components/hotspot-popup';

const ReactUI = (props) => {
  const { setIsIntroduction, controls, refScene, setActiveObjectIds, resetActiveColorModel, activeObjectIds } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const page = useSelector((state) => state.home.page);

  const pagesSettings = useSelector((state) => state.scene.pagesSettings);
  const isLoading = useSelector((state) => state.scene.isLoading);
  const transportOptionDistricts = useSelector((state) => state.home.transportOptionDistricts);
  const isShowFilterPanel = useSelector((state) => state.home.isShowFilterPanel);
  const isShowHotspotalleryModal = useSelector((state) => state.home.isShowHotspotalleryModal);
  const modal = useSelector((state) => state.exploreModal.modal);
  const activeCategory = useSelector((state) => state.home.activeCategory);
  const isShowFuture = useSelector((state) => state.home.isShowFuture);
  const categories = useSelector((state) => state.category.categories);

  const HandleSetActivePage = React.memo((props) => {
    if (props.pagesSettings == null) {
      return <div />;
    }
    const settings = props.pagesSettings.filter(setting => setting.name == page);
    if (settings.length == 0) {
      return <div />;
    }
    if (controls.current == null) {
      return <div />;
    }
    const setting = settings[0];

    setting.active_objects != null && setting.active_objects.length > 0 && setActiveObjectIds(setting.active_objects);

    if (Array.isArray(setting.show_layers) &&
      Array.isArray(setting.enable_layers)) {
      controls.current.hideAll();
      controls.current.disableAll();

      controls.current.showAndEnableLayer(LAYERS.BOTH);
      for (let i = 0; i < setting.show_layers.length; i += 1) {
        controls.current.showLayer(setting.show_layers[i]);
      }
      for (let i = 0; i < setting.enable_layers.length; i += 1) {
        controls.current.enableLayer(setting.enable_layers[i]);
      }

      if (isShowFuture) {
        controls.current.showLayer(LAYERS.FUTURE);
      } else {
        controls.current.showLayer(LAYERS.EXISTING);
      }
    }
    
    let isAnimated = false;
    
    if (controls.current.currentPage == page) {
      if (activeCategory == undefined || activeCategory == controls.current.activeCategory) {
        return <div />;
      }
      controls.current.activeCategory = activeCategory;

      for (const index in categories) {
        let category = categories[index];
        if (category.id == activeCategory && category.cam_position && category.cam_focus_point_position) {
          isAnimated = true;
          controls.current.lookAtAndMovePosition(
            threePosition(category.cam_focus_point_position),
            threePosition(category.cam_position), () => {
              controls.current.state = -1;
            });
        }
      }

      return <div />;
    }
    controls.current.currentPage = page;

    if (!isAnimated && setting.camera && setting.camera_look_at_position) {
      if (controls.current.state == undefined) {
        controls.current.state = -1;
      } else {
        const camPosition = threePosition(setting.camera);
        const camLookAtPosition = threePosition(setting.camera_look_at_position);

        controls.current.lookAtAndMovePosition(camLookAtPosition, camPosition, () => {
          controls.current.state = -1;
        });
      }
    }
    return <div />
  });
  HandleSetActivePage.displayName = 'HandleSetActivePage';

  const handleClickCurrent = () => {
    dispatch(reqSetActiveCategory());
    dispatch(reqSetIsShowFilterPanel(false));
    dispatch(reqSetIsShowFuture(false));
    controls.current.showLayer(LAYERS.EXISTING);
  };

  const handleClickFuture = () => {
    dispatch(reqSetActiveCategory());
    dispatch(reqSetIsShowFilterPanel(false));
    dispatch(reqSetIsShowFuture(true));
    controls.current.showLayer(LAYERS.FUTURE);
  };

  const handleClickNavMenu = (categoryId) => {
    dispatch(reqSetIsShowGalleryModal(false));

    if (categoryId == activeCategory) {
      dispatch(reqSetIsShowFilterPanel(false));
      dispatch(reqFilterCategory([]));
      dispatch(reqSetActiveCategory());
    } else {
      dispatch(reqSetIsShowFilterPanel(true));
      dispatch(reqFilterCategory([]));
      dispatch(reqSetActiveCategory(categoryId));
    }
  }

  const handleClickTransportOptions = useCallback((ids, model) => {
    if (refScene.current == null) {
      return;
    }
    resetActiveColorModel(model);
    setActiveObjectIds(ids);
    if (controls.current != null) {
      let selectedHotspot = controls.current.selectedHotspot;
      if (selectedHotspot != null) {
        selectedHotspot.material.map = selectedHotspot.userData.texture;
      }
      controls.current.selectedHotspot = null;

      let selectedObject = controls.current.selectedObject;
      if (selectedObject != null) {
        setColor2(selectedObject.userData.color, selectedObject);
        selectedObject.userData.isActive = false;
      }
      controls.current.selectedObject = null;
    }
  });

  const resetState = () => {

    handleClickTransportOptions([], {});

    if (controls.current != null) {
      controls.current.needReloadSelectedHotspotId = true;

    }

    if (refScene.current != null) {
      transportOptionDistricts.forEach((tp) => {
        tp.hidden_when_not_selected.forEach(id => {
          let object = refScene.current.getObjectByName(id);
          if (object != null) {
            object.layers.set(object.userData.layer ?? LAYERS.DISABLE);
          }
        })
      });
    }

    resetActiveColorModel({});
    setActiveObjectIds([]);
    dispatch(reqSetActiveTransportOption([]));
    dispatch(reqSetActiveTransportOptionDistricts([]));
    dispatch(reqSetSelectedUnit(''));
    dispatch(reqIsShowGallery(false));
    dispatch(reqSetIsShowExploreModal(false));
    dispatch(reqIsShowFloorplan(false));
    dispatch(reqSetIsShowPrecinctExploreDetail(false));
    dispatch(reqSetIsShowPrecinctDetail(false));
    dispatch(reqSetPage(PAGES.LANDING_PAGE));
    dispatch(reqSetActivePrecinctID(null));
    dispatch(reqSetIsShowGalleryModal(false));
    dispatch(reqSetIsShowEndGuideTenantSession(false));
    dispatch(reqSetIsShowReplayVideo(false));
  };

  return (
    <>
      <HandleSetActivePage pagesSettings={pagesSettings} page={page} />
      { !isShowFuture && <HeaderNav handleClickNavMenu={handleClickNavMenu} /> }

      {page == PAGES.ONBOARD_PAGE && (
        <Loading
          isLoading={isLoading}
          setIsIntroduction={setIsIntroduction}
        />
      )}

      <TransitionGroup>
        {
          isShowFilterPanel && (
          <CSSTransition
            timeout={600}
            classNames="fade-left"
            unmountOnExit
          >
            <FilterPanel/>
          </CSSTransition>
          )
        }
      </TransitionGroup>
      <TransitionGroup>
        {
          activeCategory && (
          <CSSTransition
            timeout={1500}
            classNames="fade-collapse"
            unmountOnExit
          >
            <FilterPanelCollapse />
          </CSSTransition>
          )
        }
      </TransitionGroup>
      { isShowHotspotalleryModal && modal?.name != undefined &&
        (
          <TransitionGroup>
            <CSSTransition timeout={50} classNames="fade-item" unmountOnExit>
              <HotspotPopup />
            </CSSTransition>
          </TransitionGroup>
        )
      }

      <BottomNav handleClickCurrent={handleClickCurrent} handleClickFuture={handleClickFuture}/>
    </>
  );
};

export default ReactUI;

