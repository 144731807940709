import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { reqGetCategoryList } from "../../reduxs/category/action";

import './index.scss';

const HeaderNav = (props) => {
  const { handleClickNavMenu } = props;
  const dispatch = useDispatch();
  const activeCategory = useSelector((state) => state.home.activeCategory);
  const categories = useSelector((state) => state.category.categories);

  useEffect(() => {
    dispatch(reqGetCategoryList());
  }, []);

  const getActiveClass = (categoryId) => {
    return activeCategory == categoryId ? 'active' : '';
  }

  const renderListCategory = () => {
    return categories.map((item, index) => {
      return (
        <li
          key={index}
          onClick={() => handleClickNavMenu(item.id)}
          className={`${getActiveClass(item.id)}`}>{item.name}</li>
      )
    })
  }

  return (
    <div className="wrap-header-nav">
      <ul className="container">
        {renderListCategory()}
      </ul>
    </div>
  )
}

export default HeaderNav;
