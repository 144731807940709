import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { PAGES } from "../../constants/options";
import { reqGetSubCategoryList } from "../../reduxs/category/action";
import { reqFilterCategory, reqSetIsShowFilterPanel, reqSetIsShowGalleryModal, reqSetPage } from "../../reduxs/home/action";
import './index.scss';

const FilterPanel = () => {
  const dispatch = useDispatch();
  const activeCategory = useSelector((state) => state.home.activeCategory);
  const filterCategory = useSelector((state) => state.home.filterCategory);
  const subCategories = useSelector((state) => state.category.subCategories);
  const isShowFilterPanel = useSelector((state) => state.home.isShowFilterPanel);

  useEffect(() => {
    dispatch(reqGetSubCategoryList({
      'category_id[equalId]': activeCategory,
    }));
  }, [activeCategory])

  const handleClose = () => {
    dispatch(reqSetPage(PAGES.LANDING_PAGE));
    dispatch(reqSetIsShowFilterPanel(false));
  }

  const onFilterCategory = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;
    dispatch(reqSetIsShowGalleryModal(false));

    if (isChecked) {
      dispatch(reqFilterCategory([...filterCategory, value]));
    } else {
      dispatch(reqFilterCategory((filterCategory || []).filter(i => i !== value)));
    }
  }

  const onClearFilter = () => {
    dispatch(reqFilterCategory([]));
  }

  const isChecked = (value) => {
    return filterCategory.includes(value);
  }

  const renderFilterData = () => {
    return (subCategories || []).map((item, index) => {
      return (
        <li key={index} className="filter-item">
          <div>
            <div className="pretty p-default">
              <input checked={isChecked(item.id)} value={item.id} type="checkbox" onChange={(e) => onFilterCategory(e)} />
              <div className="state">
                <label>{item.name}</label>
              </div>
            </div>
          </div>
        </li>
      )
    })
  }

  return (
    <div className={`wrap-filter-panel ${activeCategory == '62e8aa71443f629cf224bcbf' ? 'hidden' : ''} ${isShowFilterPanel ? '' : 'hide'}`}>
      <div className="wrap-hide-icon" onClick={handleClose}>
        <img src="./icons/hide.png" alt="" />
      </div>
      <ul className="filter-list">
        {renderFilterData()}
      </ul>
      <div className="wrap-close-btn" onClick={onClearFilter}>
        <div className="close-icon">
          <img src="./icons/close_icon.png" alt="" />
          <label htmlFor="">CLEAR FILTERS</label>
        </div>
      </div>
    </div>
  );
}

export default FilterPanel
