import * as Types from './type';
import modalApi from '../../apis/api/modal';

const actSetExploreModal = (data) => ({
  type: Types.SET_EXPLORE_MODAL,
  data,
});

export const actSetIsShowExploreModal = (data) => ({
  type: Types.SET_IS_SHOW_EXPLORE_MODAL,
  data,
});

export const reqSetExploreModal = (id) => (dispatch) => {
  if (!id) {
    dispatch(actSetExploreModal(null));
    return;
  }

  return modalApi
    .getModalDetail(id)
    .then((data) => {
      dispatch(actSetExploreModal(data));
    })
};

export const reqSetIsShowExploreModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowExploreModal(data));
}
