import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { reqSetExploreModal } from "../../reduxs/explore-modal/action";
import { reqSetIsShowGalleryModal } from "../../reduxs/home/action";
import './index.scss';

const HotspotPopup = () => {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.exploreModal.modal);

  const handleClosePopup = () => {
    dispatch(reqSetIsShowGalleryModal(false));
    dispatch(reqSetExploreModal());
  }

  return (
    <div className="wrap-hotspot-popup">
      <div className="row">
        <div className="wrap-shop-description col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
          <div className="shop-description">
            <h1 className="shop-name">{modal.name}</h1>
            <div className="shop-location">
              <img src="./images/location.png" alt="" />
              <label htmlFor="Location">Location</label>
            </div>
            <div className="shop-schedule">
              <label className="title" htmlFor="">Opening Hours</label>
              <div className="schedule">
                {
                  modal.opening_times.map((time, index) => {
                    return <p key={index} className="m-0">{time}</p>
                  })
                }
              </div>
              <a href={modal.permalink} target="__blank" className="text-decoration-none">
                <label className="detail-link cursor-pointer" htmlFor="">View All Details</label>
              </a>
            </div>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 h-100">
          <div className="wrap-shop-image">
            <img className="img-fluid" src={modal.image_feature} alt="" />
          </div>
        </div>
      </div>
      <div className="wrap-close-btn" onClick={handleClosePopup}>
        <img className="img-fluid" src="./icons/close_hotspot_modal.svg" alt="" />
      </div>
    </div>
  );
}

export default HotspotPopup;
