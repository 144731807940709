import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { reqSetIsShowFilterPanel } from "../../reduxs/home/action";
import './index.scss';

const FilterPanelCollapse = () => {
  const dispatch = useDispatch();
  const activeCategory = useSelector((state) => state.home.activeCategory);

  const handleShowFilterPanel = () => {
    dispatch(reqSetIsShowFilterPanel(true));
  }

  return (
    <div className={`wrap-filter-panel-collapse ${activeCategory == '62e8aa71443f629cf224bcbf' ? 'hidden' : ''}`} onClick={handleShowFilterPanel}>
      <img className="img-fluid" src="./icons/filter_collapse.png" alt="" />
    </div>
  )
}

export default FilterPanelCollapse;
