import masterData from './master-data';

export const AVAILABILITY_STATUS_OPTION = [
  {
    value: masterData.AVAILABILITY_STATUS_ENUM.AVAILABLE_NOW,
    title: 'Available now',
  },
  {
    value: masterData.AVAILABILITY_STATUS_ENUM.RESERVED,
    title: 'Reserved',
  },
  {
    value: masterData.AVAILABILITY_STATUS_ENUM.MUTILPLE_OFFERS,
    title: 'Multiple Offers',
  },
  {
    value: masterData.AVAILABILITY_STATUS_ENUM.SOLD,
    title: 'Sold',
  },
  {
    value: masterData.AVAILABILITY_STATUS_ENUM.RELEASING_SOON,
    title: 'Releasing soon',
  }
];

export const MEDIA_TYPE_OPTION = [
  {
    value: masterData.MEDIA_TYPE_ENUM['360_PANO_TOUR'],
    title: '360 Pano tour',
  },
  {
    value: masterData.MEDIA_TYPE_ENUM.FLOORPLAN,
    title: 'Floorplan',
  },
  {
    value: masterData.MEDIA_TYPE_ENUM.IMAGE,
    title: 'Image',
  },
  {
    value: masterData.MEDIA_TYPE_ENUM.INTERIOR_ID,
    title: 'Interior id',
  },
  {
    value: masterData.MEDIA_TYPE_ENUM.VIDEO,
    title: 'Video',
  },
];

export const PROPERTY_TYPE_OPTION = [
  {
    value: masterData.PROPERTY_TYPE_ENUM.APARTMENT,
    title: 'Apartment',
  },
  {
    value: masterData.PROPERTY_TYPE_ENUM.HOUSE,
    title: 'House',
  },
  {
    value: masterData.PROPERTY_TYPE_ENUM.LAND,
    title: 'Land',
  },
  {
    value: masterData.PROPERTY_TYPE_ENUM.OFFICE,
    title: 'Office',
  },
  {
    value: masterData.PROPERTY_TYPE_ENUM.TOWNHOUSE,
    title: 'Townhouse',
  },
  {
    value: masterData.PROPERTY_TYPE_ENUM.UNIT,
    title: 'Unit',
  },
  {
    value: masterData.PROPERTY_TYPE_ENUM.VILLA,
    title: 'Villa',
  },
];

export const UNIT_OPTION = [
  {
    value: masterData.UNIT_ENUM.ACRE,
    title: 'Acre',
  },
  {
    value: masterData.UNIT_ENUM.HECTARE,
    title: 'Hectare',
  },
  {
    value: masterData.UNIT_ENUM.SQUARE,
    title: 'Square',
  },
  {
    value: masterData.UNIT_ENUM.SQUARE_METER,
    title: 'Square meter',
  },
];

export const BEDROOM_OPTION = [
  {
    title: '2',
    value: 2,
  },
  {
    title: '3',
    value: 3,
  },
  {
    title: '4',
    value: 4,
  },
  {
    title: '5',
    value: 5,
  },
  {
    title: '6',
    value: 6,
  },
];

export const PARKING_STALLS = [
  {
    title: '2',
    value: 2,
  },
  {
    title: '3',
    value: 3,
  },
];

export const PAGES = {
  NONE: 'NONE',
  INTRODUCE_PAGE: 'INTRODUCE_PAGE',
  AMENITY_PAGE: 'AMENITY_PAGE',
  UNIT_EXPLORER_PAGE: 'UNIT_EXPLORER_PAGE',
  GALLERY_PAGE: 'GALLERY_PAGE',
  IMMERSE_PAGE: 'IMMERSE_PAGE',
  TEAM_PAGE: 'TEAM_PAGE',
  LOCATION_PAGE: 'LOCATION_PAGE',
  ONBOARD_PAGE: 'ONBOARD_PAGE',
  EXCUTIVE_SUMMARY_PAGE: 'EXCUTIVE_SUMMARY_PAGE',
  SEARCH_PAGE: 'SEARCH_PAGE',
  LANDING_PAGE: 'LANDING_PAGE',
  EXPLORE_PRECINCT_PAGE: 'EXPLORE_PRECINCT_PAGE',
  EXPLORE_DISTRICT_PAGE: 'EXPLORE_DISTRICT_PAGE',
  EXPLORE_CITY_PAGE: 'EXPLORE_CITY_PAGE',
  PRECINCT_SUMMANRY_PAGE: 'PRECINCT_SUMMANRY_PAGE',
  ENVIRONMENT_PAGE: 'ENVIRONMENT_PAGE',
  END_GUIDE_TENANT_SESSION_PAGE: 'END_GUIDE_TENANT_SESSION_PAGE',
  CONTACT_PAGE: 'CONTACT_PAGE',
  ABOUT_PAGE: 'ABOUT_PAGE',
  GUIDED_TOUR_PAGE: 'GUIDED_TOUR_PAGE',
  FASHION_PAGE: 'FASHION_PAGE',
};

export const LAYERS = {
  BOTH: 0,
  FUTURE: 1,
  EXISTING: 2,

  DISABLE: 31,
};

export const OBJECT_TYPES = {
  NONE: 'none',
  DO: 'do',
  FD: 'fd',
  UNIT: 'unit',
  AREA: 'area',
  AMENITY: 'amenity',
};
export const HOTSPOT_TYPES = {
  FUTURE_ITEM: 'future_item',
  PRESENT_ITEM: 'present_item'
};

export default {
  AVAILABILITY_STATUS_OPTION,
  MEDIA_TYPE_OPTION,
  PROPERTY_TYPE_OPTION,
  UNIT_OPTION,
  PARKING_STALLS,
  PAGES,
  LAYERS,
  OBJECT_TYPES,
  HOTSPOT_TYPES,
};

export const GROSS_RENT_OPTION = [
  {
    id: 1,
    text: "$50 - 100 PSM",
    min: 50,
    max: 100,
  },
  {
    id: 2,
    text: "$100 - 200 PSM",
    min: 100,
    max: 200,
  },
  {
    id: 3,
    text: "$200+ PSM",
    min: 200
  },
]

export const CENTRE_OPTION = [
  {
    text: "Existing Centre",
    value: 'Existing Centre'
  },
  {
    text: "Cinema Building",
    value: 'Cinema Building',
  },
  {
    text: "Fresh Food",
    value: 'Fresh Food',
  },
];

export const LEVEL_OPTION = [
  {
    text: "Ground",
    value: 'GROUND',
  },
  {
    text: "Level 1",
    value: 'LEVEL ONE',
  },
];

export const AREA_OPTION = [
  {
    id: 1,
    min: 10,
    max: 50,
    text: "10 - 50 sqm"
  },
  {
    id: 2,
    min: 50,
    max: 200,
    text: "50 - 200 sqm"
  },
  {
    id: 3,
    min: 200,
    max: 500,
    text: "200 - 500 sqm"
  },
  {
    id: 4,
    min: 500,
    text: "500+ sqm"
  },
]

export const PRECINCT_OPTION = [
  {
    text: "Entertainment and F&B",
    value: 'Entertainment and F&B',
  },
  {
    text: "General Retail",
    value: 'General Retail',
  },
  {
    text: "Health & Wellbeing",
    value: 'Health & Wellbeing',
  },
  {
    text: "Lifestyle & Tourism",
    value: 'Lifestyle & Tourism'
  },
  {
    text: "Commercial Office",
    value: 'Commercial Office'
  },
  {
    text: "Fresh Food and Daily Needs",
    value: 'Fresh Food and Daily Needs'
  },
]

export const CATEGORIES = {
  FASHION: 'FASHION',
  RESTAURANTS_AND_BARS: 'RESTAURANTS_AND_BARS',
  ARTS_AND_CULTURE: 'ARTS_AND_CULTURE',
  CONVENIENCES: 'CONVENIENCES',
  SERVICES: 'SERVICES',
  CONSULTANCIES: 'CONSULTANCIES',
  MARINA: 'MARINA',
}

export const FILTER_DATA = {
  FASHION: [
    {
      title: "Men's Fashion",
      value: "mens_fashion"
    },
    {
      title: "Shoes",
      value: "shoes"
    },
    {
      title: "Women's Fashion",
      value: "womens_fashion"
    },
    {
      title: "Jewellry & watches",
      value: "jewellry_watches"
    },
    {
      title: "Accessories",
      value: "accessories"
    },
    {
      title: "Perfumery",
      value: "perfumery"
    },
    {
      title: "Children's Fashion",
      value: "childrens_fashion"
    },
    {
      title: "Swimwear & lingerie",
      value: "swimear_lingerie"
    },
    {
      title: "Lifestyle",
      value: "lifestyle"
    }
  ],
  ARTS_AND_CULTURE: [
    {
      title: "Souvenirs",
      value: "souvernirs"
    },
    {
      title: "Galleries",
      value: "galleries"
    }
  ]
}
