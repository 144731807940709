import ApiClient from '../apiClient';
import { authFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);

const getSubCategoryList = (data) => client.get(endPoint.SUB_CATEGORY_LIST, data);

export default {
  getSubCategoryList,
};
